/* eslint-disable @typescript-eslint/naming-convention */
import { DecimalPipe } from '@angular/common';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Dialog } from '@capacitor/dialog';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { LoadingController, Platform } from '@ionic/angular';
import { BehaviorSubject, lastValueFrom, scan } from 'rxjs';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  public downloadProgress$: BehaviorSubject<DownloadProgress> = new BehaviorSubject(null);
  private fileIndex: FilePath[] = [];

  constructor(
    private platform: Platform,
    private decimalPipe: DecimalPipe,
    private httpClient: HttpClient,
    private utilitiesService: UtilitiesService) {
    this.createFileIndex();
  }

  /**
   * Call this function on app init.
   *
   * @param directory e.g. 'podcast', ...
   */
  async createDirectory(directory: DownloadDirectory) {
    try {
      if (this.platform.is('capacitor')) {
        await Filesystem.mkdir({
          directory: Directory.Data,
          path: directory,
          recursive: false,
        });
      }
      // console.log(`Created ${directory}/ directory`);
    } catch (e) {
      // console.log(`${directory}/ directory exists`);
    }
  }

  /**
   * Downloads a file to a specific directory and returns the result as `File URI`, `Capacitor URI` or `Base64`.
   * NOTICE: Returns always `Base64` in browser.
   *
   * @param options DownloadFileOptions
   */
  async downloadFile(options: DownloadFileOptions): Promise<string> {

    await this.utilitiesService.presentLoading('Datei anfordern...', 5000);

    const { size, destination } = await this.getFileMeta(options?.url, options?.headers);

    await this.utilitiesService.dismissLoading();

    // Show prompt
    if (this.platform.is('capacitor') && options?.showPrompt) {

      const { value } = await Dialog.confirm({
        title: `Jetzt laden?`,
        message: `Die Größe des Downloads beträgt ${this.bytesToMegabytes(size)} MB.`,
        okButtonTitle: 'Ja',
        cancelButtonTitle: 'Nein',
      });

      if (!value) {
        return null;
      }
    }

    // Use final url after all redirects
    options.url = destination;

    try {
      // Download the file and return local file url
      return this.downloadFileNative(options);

    } catch (error) {
      console.error(error);
      await this.utilitiesService.showToast('Fehler beim Download der Datei');
    }

  }

  /**
   * Same as downloadFile(), but it uses native features instead of angular http
   * to download files and doesn't provide download progress.CapacitorHTTP doesn't
   * have the ability to provide the download progress.
   *
   * @see https://capacitorjs.com/docs/apis/http#large-file-support
   * @param options DownloadFileOptions
   * @returns capacitorURI on device and blob uri on browser.
   */
  async downloadFileNative(options: DownloadFileOptions): Promise<string> {

    // Init loading indicator
    this.utilitiesService.presentLoading('Download...');

    // Download the file
    const dlUrl = (options?.useCorsProxy || !this.platform.is('capacitor'))
      ? ('https://corsproxy.io/?' + encodeURIComponent(options.url)) : options.url; // Todo: Add CorsProxy Pro

    const result = await Filesystem.downloadFile({
      url: dlUrl,
      directory: Directory.Data,
      path: `/${options.directory}/${options.filename}`,
      recursive: true
    });

    await this.createFileIndex();
    await this.utilitiesService.dismissLoading();

    // Return local file uri
    if (this.platform.is('capacitor')) {
      return Capacitor.convertFileSrc(result.path);
    } else {
      return URL.createObjectURL(result.blob);
    }

  };


  /**
   * Return a URI to the requested file
   * - use `file://` uri to pass file into native plugins
   * - use `capacitor://` uri to use file in js/html
   * - use `blob://` uri in browser or as fallback for `capacitor://` uri
   *
   * @param options GetFileOptions
   * @returns File Path, Capacitor URI or Blob URI
   */
  async getFileUri(options: GetFileOptions): Promise<string> {
    if (this.platform.is('capacitor')) {
      try {
        const { uri } = await Filesystem.getUri({
          directory: Directory.Data,
          path: `${options.directory}/${options.filename}`
        });

        switch (options.responseType) {
          case 'fileUri':
            return uri;
          case 'capacitorUri':
            return Capacitor.convertFileSrc(uri);
          default:
            const request = this.httpClient.get(Capacitor.convertFileSrc(uri), {
              responseType: 'blob'
            });
            const blob = await lastValueFrom(request);
            return URL.createObjectURL(blob);
        }

      } catch (e) {
        console.error(e);
        return null;
      }
    }
  }

  /**
   * Get a file as base64 string from file system
   *
   * @param options FilePath
   * @returns Base64 String
   */
  getFile(options: FilePath) {
    if (this.platform.is('capacitor')) {
      return Filesystem.readFile({
        directory: Directory.Data,
        path: `${options.directory}/${options.filename}`
      });
    }
  }

  async writeFile(options: WriteFileOptions) {
    // Todo
    await this.createFileIndex();
  }

  /**
   * This function
   * - shows prompt and asks user if he really wants to remove file
   * - removes file from directory
   * - updates the local episodes object (this.podcastEpisodes)
   *
   * @param options DeleteFileOptions
   */
  async deleteFile(options: DeleteFileOptions): Promise<boolean> {
    if (this.platform.is('capacitor')) {
      try {
        if (options?.showPrompt) {
          const { value } = await Dialog.confirm({
            title: 'Download entfernen?',
            message: 'Soll der Download entfernt werden?',
            okButtonTitle: 'Ja',
            cancelButtonTitle: 'Nein',
          });
          if (!value) {
            return false;
          }
        }
        await Filesystem.deleteFile({
          directory: Directory.Data,
          path: `${options.directory}/${options.filename}`,
        });
        await this.createFileIndex();
        return true;
      } catch (e) {
        if (options?.showPrompt) {
          await Dialog.alert({
            title: 'Fehler',
            message: 'Es ist ein Fehler beim Löschen der Datei aufgetreten.'
          });
        }
        console.error(e);
      }
    }
  }

  /**
   * Delete a directory and it's contents!
   *
   * @param directory DownloadDirectory
   */
  async deleteDirectory(directory: DownloadDirectory) {
    await Filesystem.rmdir({
      directory: Directory.Data,
      path: directory,
      recursive: true
    });
    await this.createFileIndex();
    console.log(`Deleted Directory ${Directory.Data}/${directory}`);
  }

  /**
   * This function follows all redirects and returns the destination url and file size
   *
   * @param url https://...
   * @returns bytes
   */
  async getFileMeta(url: string, customHeader?: any): Promise<FileMetaResponse> {
    const headRequest = async () => {
      const request = this.httpClient.head(url, {
        headers: customHeader || {},  // Set default to empty object
        observe: 'response'
      });
      try {
        const response: any = await lastValueFrom(request);
        const headers: HttpHeaders = response.headers;
        return {
          location: headers.get('Location'),
          size: Number(headers.get('Content-Length')),
          url: response.url
        };
      } catch (error) {
        console.error('Error fetching HEAD request:', error);
        throw error;
      }
    };

    let result = await headRequest();
    console.log('RESUUUULT', result);

    let retryCount = 0;  // Add a counter to prevent infinite loop
    const maxRetries = 5;

    while (typeof result.location === 'string' && retryCount < maxRetries) {
      result = await headRequest();
      console.log('RESUUUULT', result);
      retryCount++;
    }

    if (retryCount >= maxRetries) {
      console.error('Max retries reached. Exiting.');
      return null;
    }

    console.log('RESULT: ', result);

    return {
      destination: result.url,
      size: result.size,
    };
  }

  /**
   * Get list of downloaded files
   *
   * @returns list of filenames
   */
  async getDownloadedFiles(directory?: DownloadDirectory): Promise<FilePath[]> {
    return directory ?
      this.fileIndex.filter(item => item.directory === directory)
      : this.fileIndex;
  }

  /**
   * Deletes all files that where created by the app
   */
  async clearAllFiles() {
    const directories: string[] = [];

    // Get created directories
    for (const item of this.fileIndex) {
      if (!directories.includes(item.directory)) {
        directories.push(item.directory);
      }
    }
    console.log('Directories to delete:', directories);

    // Delete contents of the directories
    for (const directory of directories) {
      await Filesystem.rmdir({
        directory: Directory.Data,
        path: directory,
        recursive: true
      });
    }

    // Update File Index
    await this.createFileIndex();
  }

  /**
   * Check if a file is downloaded
   *
   * @param path e.g. "bitcoin.pdf"
   * @returns true / false
   */
  async fileExists(path: FilePath) {
    const fileIndex = await this.getDownloadedFiles();
    return fileIndex.some(res => res.directory === path.directory && res.filename === path.filename);
  }

  /**
   * Transforms bytes to megabytes
   *
   * @param bytes file size in bytes
   * @returns file size in megabytes
   */
  bytesToMegabytes(bytes: number | string) {
    const mb = Number(bytes) / 1000000;
    return this.decimalPipe.transform(mb, '1.1-2');
  }

  /**
   * Get the file name out of the url
   *
   * @param url e.g. "https://bitcoin.org/bitcoin.pdf"
   * @returns e.g. "bitcoin.pdf"
   */
  extractFileNameFromUrl(url: string) {
    return new URL(url).pathname.split('/').pop();
  }

  /**
   * Call this function every time you added or removed files!
   * The file index keeps track of downloaded files,
   * so the requests to the file system can be reduced.
   * This function reads all local files and puts them into
   * an array og objects.
   */
  private async createFileIndex() {
    if (this.platform.is('capacitor')) {
      console.log('Creating File Index...');
      this.fileIndex = [];
      // Get root dir contents
      const root = await Filesystem.readdir({
        directory: Directory.Data,
        path: '/'
      });
      // Loop over subdirectories in root dir
      for (const rootFile of root.files) {
        if (rootFile.type === 'directory') {
          console.log('Found Dir', rootFile.name);
          const subDir = await Filesystem.readdir({
            directory: Directory.Data,
            path: rootFile.name // podcast
          });
          // Loop over files in subdirectories
          for (const subDirFile of subDir.files) {
            if (subDirFile.type === 'file') {
              console.log('Found File', rootFile.name + '/' + subDirFile.name);
              this.addFileToIndex({
                directory: rootFile.name as DownloadDirectory,
                filename: subDirFile.name
              });
            }
          }
        }
        console.log('FileIndex', this.fileIndex);
      }
    }
  }

  private addFileToIndex(path: FilePath) {
    this.fileIndex.push(path);
  }

  private removeFileFromIndex(path: FilePath) {
    this.fileIndex = this.fileIndex.filter(res => !(res.directory === path.directory && res.filename === path.filename));
  }

  private removeDirFromIndex(directory: string) {
    this.fileIndex = this.fileIndex.filter(res => res.directory !== directory);
  }

  private isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  private isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress
      || event.type === HttpEventType.UploadProgress;
  }

}

interface DownloadFileOptions {
  url: string;
  directory: DownloadDirectory;
  filename: string;
  headers?: any;
  responseType?: ResponseType;
  showPrompt?: boolean;
  useCorsProxy?: boolean;
}

interface WriteFileOptions extends FilePath {
  blob: Blob;
}

export interface FilePath {
  directory: DownloadDirectory;
  filename: string;
}

interface DeleteFileOptions extends FilePath {
  showPrompt?: boolean;
}

export interface GetFileOptions extends FilePath {
  responseType: ResponseType;
}

export interface GetFileResponse {
  blob?: Blob;
  fileUri?: string;
  capacitorUri?: string;
}

export interface FileMetaResponse {
  size?: number;
  destination?: string;
}

export interface DownloadProgress {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
  progress: number;
  content: Blob | null;
}

export type ResponseType = 'fileUri' | 'capacitorUri' | 'blob';

export enum DownloadDirectory {
  Podcast = 'podcast'
}
