import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysUntilToday'
})
export class DaysUntilTodayPipe implements PipeTransform {

  transform(date: string, format?: 'milliseconds'): number {
    const oneDay = 24 * 60 * 60 * 1000;
    const today = new Date().getTime();
    console.log('today', today);
    const previousDate = new Date(date).getTime();
    console.log('previousDate', previousDate);
    return Math.round(Math.abs((today - previousDate) / oneDay));
  }

}
