import { Component, OnInit, ChangeDetectionStrategy, NgZone, inject } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { compareVersions } from 'compare-versions';
// Services
import { SanityService } from './services/sanity.service';
import { LoggerService } from './services/logger.service';
import { SettingsService } from './services/settings.service';
import { ContentService } from './services/content.service';
import { StorageService } from './services/storage.service';
import { UtilitiesService } from './services/utilities.service';
import { UserService } from './services/user.service';
import { DownloadDirectory, FileService } from './services/file.service';
import { UpdateNotifierService } from './services/utils/update-notifier.service';
import { CacheService } from 'ionic-cache';
// Capacitor
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import { SuppressLongpressGesture } from 'capacitor-suppress-longpress-gesture';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { TextZoom } from '@capacitor/text-zoom';
import { LOG_LEVEL, Purchases, PurchasesConfiguration } from '@revenuecat/purchases-capacitor';
import OneSignal, { LogLevel, NotificationClickEvent } from 'onesignal-cordova-plugin';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  private updateNotifier = inject(UpdateNotifierService);
  private sanityService = inject(SanityService);
  // private otaUpdate = inject(OTAUpdateService);

  constructor(
    private platform: Platform,
    private settingsService: SettingsService,
    private contentService: ContentService,
    private storageService: StorageService,
    private ngZone: NgZone,
    private cache: CacheService,
    private fileService: FileService,
    private utilitiesService: UtilitiesService,
    private userService: UserService,
    private loggerService: LoggerService,
    private router: Router,
    private navController: NavController) { }

  async ngOnInit() {
    await this.storageService.init(); // Needs to stay at the very top!
    const isLoggedIn = await this.initOAuth();

    // The following functions execute synchronous. No specific order needed.
    this.initDeepLinkListener();
    this.exposeCssVariables();
    this.subscribeToBackButtonEvent();
    this.cache.setDefaultTTL(60 * 60 * 24); // 1 day
    this.settingsService.checkBetaTesterState();

    // Verifies currently installed ota and checks for new one
    // this.otaUpdate.checkForOTAUpdates(); // Temporarily disabled

    // Things that need to be done before splash screen gets hidden
    const utilitiesInit = await Promise.allSettled([
      this.initTextZoom(),
      this.clearCacheIfAppWasUpdated(),
      this.logoutIfEnvironmentChanged(),
      this.settingsService.initTheme()
    ]);
    await this.hideSplashScreen();
    await this.initRevenueCat();
    console.log('Utilities Init:', utilitiesInit);

    // Init native plugins
    const nativePluginsInit = await Promise.allSettled([
      this.initOneSignal(),
      this.initFilesystem(),
      this.initFirebaseEvents(),
      this.setKeyboardResizeMode(),
      this.suppressLongpressGesture(),
      this.updateNotifier.checkForUpdates()
    ]);
    console.log('Native Plugin Init:', nativePluginsInit);

    // Add methods that depend on native plugins below:

    await this.userService.checkSubscriptionState();
    if (isLoggedIn) {
      await this.userService.syncUserToThirdParty();
    }

    this.preloadData();
  }

  /**
   * Hide the splash screen.
   */
  private async hideSplashScreen() {
    try {
      if (this.platform.is('capacitor')) {
        await SplashScreen.hide();
      }

    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Set keyboard resize mode to add space at the
   * Bottom of a page if keyboard is active
   */
  private async setKeyboardResizeMode() {
    try {
      if (this.platform.is('capacitor') && this.platform.is('ios')) {
        await Keyboard.setResizeMode({
          mode: KeyboardResize.Ionic
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Initialize RevenueCat instance
   */
  private async initRevenueCat() {

    if (!this.platform.is('capacitor')) {
      return;
    }

    try {
      // Enable to get debug logs in dev mode
      const logLevel = environment.production ? LOG_LEVEL.ERROR : LOG_LEVEL.DEBUG;
      await Purchases.setLogLevel({ level: logLevel });

      // Set up api keys
      const configuration: PurchasesConfiguration = {
        apiKey: this.platform.is('ios') ? environment.revenueCat.apiKeyIos : environment.revenueCat.apiKeyAndroid
      };
      await Purchases.configure(configuration);
    } catch (e) {
      console.error('Error on RevenueCat Initiation:', e);
    }
  }

  /**
   * Disable the long press gesture of the safari
   * webview to make the app fell more native.
   */
  private async suppressLongpressGesture() {
    try {
      if (this.platform.is('capacitor') && this.platform.is('ios')) {
        await SuppressLongpressGesture.activateService();
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Download podcast meta information and create podcast directory.
   */
  private async initFilesystem() {
    try {
      await this.fileService.createDirectory(DownloadDirectory.Podcast);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * This function activates firebase in general and
   * also tracks every route that gets visited by the user.
   */
  private async initFirebaseEvents() {
    try {
      await this.loggerService.enableFirebase();
      await this.loggerService.enableRouteLogging();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * If user user is logged in, the discovery document will be loaded
   * and tokens are made accessible.
   */
  private async initOAuth(): Promise<boolean> {
    const isLoggedIn = await this.userService.isLoggedIn();
    // If user has a refresh token, he is logged in!
    if (isLoggedIn) {
      await this.userService.loadTokensFromPreferences();
      await this.userService.initOAuth();
    }
    return isLoggedIn;
  }

  /**
   * Init OneSignal and handle push notification clicks.
   */
  private async initOneSignal() {

    if (!this.platform.is('capacitor')) {
      return;
    }

    // Set OneSignal device logging to verbose on dev
    if (!environment.production) {
      OneSignal.Debug.setLogLevel(LogLevel.Debug);
    }

    // Init plugin with app id
    OneSignal.initialize(environment.oneSignal.appId);

    // Exchange data with RevenueCat
    OneSignal.User.pushSubscription.addEventListener('change', ({ previous, current }) => {
      this.userService.sendOneSignalDataToRevenueCat(previous, current);
    });

    // Handle incoming push notifications
    OneSignal.Notifications.addEventListener('click', (event: NotificationClickEvent) => {
      if (event?.notification?.launchURL) {
        this.ngZone.run(() => {
          // content service will decide what to do with the given link
          this.contentService.handleLink(event.notification.launchURL, { source: 'Push Notification' });
        });
      }
    });

    const { value: permissionsRequested } = await Preferences.get({ key: 'push_permissions_requested' });
    if (!OneSignal.Notifications.hasPermission() && !OneSignal.User.pushSubscription.optedIn && !Boolean(permissionsRequested)) {
      OneSignal.User.pushSubscription.optIn();
      Preferences.set({ key: 'push_permissions_requested', value: 'yes' });
    }

    return;

    // TODO: Prompt the user for notification permissions
    // This is the better way for requesting push permissions
    const canRequestPermission = await OneSignal.Notifications.canRequestPermission();
    if (!OneSignal.Notifications.hasPermission() && canRequestPermission && !Boolean(permissionsRequested)) {
      // Bug: This doesn't resolve (https://github.com/OneSignal/OneSignal-Cordova-SDK/issues/926)
      await OneSignal.Notifications.requestPermission(true);
      OneSignal.User.pushSubscription.optIn();
      console.log('User accepted notifications');
    }
  }

  /**
   * Deep Links can be used to open specific pages in the App right from the browser.
   * Example: btcecho:/news/neuer-rekord-droht-eine-blase-im-non-fungible-token-nft-sektor-112057
   */
  private initDeepLinkListener() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const { url } = event;
      if (url) {
        // content service will decide what to do with the given link
        this.contentService.handleLink(url, { source: 'Deep Link' });
      }
    });
  }

  /**
   * Clear cache after user updates the app
   */
  private async clearCacheIfAppWasUpdated() {
    try {
      if (this.platform.is('capacitor')) {
        const storedVersion = await this.storageService.get('appVersion') as string || '1.0.0';
        const { version, build } = await App.getInfo();
        const currentVersion = `${version}.${build}`;

        // Clear cache, if current app version is higher than stored app version
        if (compareVersions(storedVersion, currentVersion) === -1) { // -1 means current version is larger
          await this.cache.clearAll();
          await this.storageService.set('appVersion', currentVersion); // Update app version in store
          console.log('Cache was cleared because App was updated to', currentVersion);
        }
      }
    } catch (e) {
      console.error('Error on function clearCacheIfAppWasUpdated()', e);
    }
  }

  /***
   * Clear storage if user changes the environment
   */
  private async logoutIfEnvironmentChanged() {
    try {
      const storedEnvironment = await this.storageService.get('environment') as string;
      const currentEnvironment = environment.name;
      // If it's the first start of the app, storedEnvironment isn't available. So set it.
      if (!storedEnvironment || storedEnvironment === '') {
        await this.storageService.set('environment', currentEnvironment);
        return;
      }
      // clear storage, if current environment is different than stored environment
      if (storedEnvironment !== currentEnvironment) {
        Promise.all([
          this.cache.clearAll(),
          this.storageService.set('environment', currentEnvironment), // Update environment in store
          this.utilitiesService.showToast(`Umgebung würde auf ${currentEnvironment} geändert. Du wurdest ausgeloggt.`)
        ]);
        console.log('Storage was cleared because environment changed to', currentEnvironment);
      }
    } catch (e) {
      console.error('Error on function clearStorageIfEnvironmentChanged()', e);
    }
  }

  private async subscribeToBackButtonEvent() {
    this.platform.backButton.subscribeWithPriority(10, () => {
      if (!this.router.url.includes('tabs')) {
        this.navController.back();
      } else {
        App.exitApp();
      }
    });
  }

  /**
   * Allow the manipulation of ionic root css variables using http get parameters.
   * Used for TestFlight. (https://eyloo.com/testflight/)
   */
  private exposeCssVariables() {
    if (!this.platform.is('capacitor')) {
      new URLSearchParams(window.location.search)
        .forEach((value, key) => {
          const transformedKey = key.replace(/[A-Z]/g, m => '-' + m.toLowerCase()); // make safeAreaTop to safe-area-top
          document.documentElement.style.setProperty('--ion-' + transformedKey, value);
        });
    }
  }

  private async initTextZoom() {

    if (!this.platform.is('capacitor')) {
      return;
    }

    const textZoom = await this.storageService.get('textZoom');
    if (textZoom) {
      await TextZoom.set({ value: textZoom });
    }
  }

  private preloadData() {
    this.sanityService.getTippBox();
  }
}
