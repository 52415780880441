import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';

// Sentry
import { init as SentryInit, BrowserTracing } from '@sentry/capacitor';
import { init as sentryAngularInit, createErrorHandler, routingInstrumentation } from '@sentry/angular-ivy';

try {
  SentryInit(
    {
      enabled: environment.production,
      dsn: 'https://47082555300649dfbfd3c4ad38781e40@o453168.ingest.sentry.io/4504564616724480',
      tracesSampleRate: 1.0,
      integrations: [
        new BrowserTracing({
          tracePropagationTargets: [
            'localhost', // Local Dev Servers
            environment.btcecho.websiteUrl, // Main API
            environment.stripe.api, // Vercel Backend
            environment.unidy.issuer
          ],
          routingInstrumentation
        }),
      ]
    },
    // Forward the init method from @sentry/angular
    sentryAngularInit
  );
} catch (error) {
  console.error('Sentry init error:', error);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: ErrorHandler, useValue: createErrorHandler({ logErrors: environment.production }) }
  ]
})
export class SentryModule { }
