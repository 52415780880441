import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-divider',
  templateUrl: './item-divider.component.html',
  styleUrls: ['./item-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemDividerComponent implements OnInit {
  @Input() text: string;
  @Input() background: 'normal' | 'secondary' = 'normal';

  constructor() { }

  ngOnInit() {}

}
