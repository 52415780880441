/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { lastValueFrom } from 'rxjs';
import { SettingsPage } from '../pages/settings/settings.page';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  public _loading: HTMLIonLoadingElement = null;
  private _toast: HTMLIonToastElement = null;

  private httpClient = inject(HttpClient);
  private toastController = inject(ToastController);
  private loadingController = inject(LoadingController);
  private modalController = inject(ModalController);

  constructor() { }

  /**
   * Sometimes WordPress can't provide the raw format of a price.
   * This method extracts the price from the given string.
   * TODO: Maybe move to utils service
   *
   * @param price e.g. "7,90&nbsp;€"
   * @returns e.g. 7.9
   */
  extractPriceFromString(formattedNumber: string) {

    if (typeof formattedNumber !== 'string') {
      return 0;
    }

    try {
      formattedNumber = formattedNumber.split('.').join('');
      formattedNumber = formattedNumber.split(',').join('.');

      return Number(formattedNumber.replace(/[^0-9.]/g, ''));

    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  /**
   * Uses native http on device and angular http client on browser
   *
   * @param url URL of the resource
   * @param options method, headers, responseType
   * @returns Body of the http request
   */
  async httpRequest(url: string, options?: {
    method?: string;
    headers?: any;
    body?: any;
    responseType?: any;
    enableCors?: boolean;
  }) {
    const request = this.httpClient.request(options?.method || 'GET', (options?.enableCors ? 'https://corsproxy.io/?' : '') + url, {
      headers: options?.headers,
      responseType: options?.responseType,
      body: options?.body
    });
    return lastValueFrom(request) as any;
  }

  addQuotationMarks(value: string | number) {
    return `"${value}"`;
  }

  removeQuotationMarks(value: string) {
    return value.replace(/"/g, '');
  }

  /**
   * Display loading indicator from LoadingController
   *
   * @param message Message to display
   * @param duration Duration in milliseconds
   * @returns Promise<HTMLIonLoadingElement>
   */
  async presentLoading(message?: string, duration?: number, cssClass?: string): Promise<HTMLIonLoadingElement> {
    if (this._loading) {
      await this.dismissLoading();
    }

    this._loading = await this.loadingController.create({
      spinner: 'circular',
      message,
      duration,
      cssClass,
      translucent: true
    });
    this._loading.present();
    return this._loading;
  }

  /**
   * Hide loading indicator from LoadingController
   *
   * @returns Promise<void>
   */
  async dismissLoading(): Promise<void> {
    console.log('Dismiss loading');
    if (!this._loading) {
      return;
    }
    await this.loadingController.dismiss();
    await this.loadingController.getTop().then(async (loading) => {
      if (loading) {
        await loading.dismiss();
      }
    });
    this._loading = null;
  }

  /**
   * Adds a console error message with error and function name
   * If userMessage is provided, it will be displayed to the user via ToastController
   *
   * @param error Error object
   * @param functionName Name of the function where the error occurred
   */
  async displayError(error: any, functionName?: string, userMessage: string = null, redirectUrl: string = null) {
    try {
      if (error && typeof error === 'object' && !error?.message) {
        error.message = 'Unknown error';
      }

      if (userMessage) {
        await this.showToast(userMessage);
      }

      if (this._loading) {
        await this.dismissLoading();
      }

      if (error) {
        if (error.critical === true) {
          throw new Error(functionName + ': ' + error?.message);
        } else {
          console.error(functionName, error);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Show errors and other messages to user
   *
   * @param error Error message
   */
  async showToast(message: string, duration: number = 3000) {
    if (this._toast) {
      return;
    }

    this._toast = await this.toastController.create({
      message,
      duration,
      position: 'bottom',
      color: 'dark'
    });

    await this._toast.present();

    // Remove toast after 2 seconds. This is to prevent multiple toasts from being displayed at the same time.
    setTimeout(() => {
      this._toast = null;
    }, 2000);
  }

  /**
   * Opens settings page in a modal
   */
  async openSettings() {
    const modal = await this.modalController.create({
      component: SettingsPage,
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      handle: false
    });
    await modal.present();
  }

  isJwtValid(token: string) {
    const jwt = this.parseJwt(token);
    const exp = jwt.exp;
    const now = new Date().getTime() / 1000;
    return exp > now;
  }

  parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    // eslint-disable-next-line max-len
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
    return JSON.parse(jsonPayload);
  }

  base64url(source) {
    // Encode in classical base64
    let encodedSource = CryptoJS.enc.Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
}

}
