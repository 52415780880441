import { inject, Injectable } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { AppUpdate, AppUpdateAvailability, FlexibleUpdateInstallStatus } from '@capawesome/capacitor-app-update';
import { UpdateNotifierComponent } from 'src/app/shared/components/update-notifier/update-notifier.component';

/**
 * @description
 * This function checks for newer versions of the app on the App Store and Play Store.
 * If a new version is available, the user will be notified.
 */

@Injectable({
  providedIn: 'root'
})
export class UpdateNotifierService {

  private platform = inject(Platform);
  private modelController = inject(ModalController);
  private toastController = inject(ToastController);

  /**
   * Check if a new app update is available in the App Store or Play Store.
   * Uses immediate updates on android and opens the app store on ios.
   *
   * @see https://developer.android.com/guide/playcore/in-app-updates#immediate
   */
  async checkForUpdates(): Promise<void> {

    // Only check for updates on mobile devices
    if (!this.platform.is('capacitor')) {
      return;
    }

    try {
      // Fetch update information
      const result = await AppUpdate.getAppUpdateInfo();

      // Check if update is available
      if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
        await this.showNotification(result.availableVersion);
      }

      return;

      // Optional: Android immediate and flexible updates are supported

      // Show built-in updater on android and link to store on ios
      if (this.platform.is('android') && result?.flexibleUpdateAllowed) {
        await this.performFlexibleUpdate();
        console.log('Performed flexible update.');
      } else
      if (this.platform.is('android') && result?.immediateUpdateAllowed) {
        const { code } = await AppUpdate.performImmediateUpdate();
        console.log('Performed immediate update. Code:', code);
      } else {
        await this.showNotification(result.availableVersion);
      }
    } catch (error) {
      console.error('Update Notifier Error:', error);
    }
  }

  /**
   * Show a dialog to the user, asking if he wants to update the app
   */
  private async showNotification(storeVersion: string): Promise<void> {
    const modal = await this.modelController.create({
      component: UpdateNotifierComponent,
      componentProps: {
        type: 'store',
        storeVersion
      },
      cssClass: 'auto-height transparent backdrop-blur',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      handle: false
    });
    await modal.present();
  }

  /**
   * ANDROID ONLY - Update the app without leaving the app.
   *
   * @see https://developer.android.com/guide/playcore/in-app-updates#flexible
   */
  private async performFlexibleUpdate() {

    try {
      await AppUpdate.startFlexibleUpdate();

      // Show Toast
      const toast = await this.toastController.create({
        message: 'Update laden:',
        duration: 60000
      });
      await toast.present();

      // Watch Download-Progress
      AppUpdate.addListener('onFlexibleUpdateStateChange', async (state) => {
        console.log('Update State: ', state);

        // Update the loading message
        if (state.installStatus === FlexibleUpdateInstallStatus.DOWNLOADING) {
          const percent = Math.round((100 * state.bytesDownloaded) / state.totalBytesToDownload);
          toast.message = `Update laden: ${percent}%`;
        }

        // If the download is complete, restart the app
        if (state.installStatus === FlexibleUpdateInstallStatus.DOWNLOADED) {
          // Remove Listeners
          await AppUpdate.removeAllListeners();
          // Update Toast
          toast.message = 'Update geladen!';
          toast.buttons = [
            {
              text: 'App neu starten',
              role: 'info',
              handler: async () => {
                await AppUpdate.completeFlexibleUpdate();
              },
            }];
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

}
