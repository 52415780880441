import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MoneyPipe } from './money.pipe';
import { FilterPipe } from './filter.pipe';
import { OrderPipe } from './order.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { DateFnsModule } from 'ngx-date-fns';
import { ReplacePipe } from './replace.pipe';
import { DaysUntilTodayPipe } from './days-until-today.pipe';
import { TimestampToHumanReadableTimePipe } from './timestamp-to-human-readable-time.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { ReversePipe } from './reverse.pipe';

@NgModule({
    imports: [
        CommonModule,
        DateFnsModule,
    ],
    declarations: [
      MoneyPipe,
      FilterPipe,
      OrderPipe,
      TimeAgoPipe,
      ReplacePipe,
      DaysUntilTodayPipe,
      TimestampToHumanReadableTimePipe,
      SafeUrlPipe,
      ReversePipe
    ],
    exports: [
      MoneyPipe,
      FilterPipe,
      OrderPipe,
      TimeAgoPipe,
      ReplacePipe,
      DaysUntilTodayPipe,
      TimestampToHumanReadableTimePipe,
      SafeUrlPipe,
      ReversePipe
    ],
    providers: [
      DecimalPipe,
      MoneyPipe,
      FilterPipe,
      OrderPipe,
      TimeAgoPipe,
      ReplacePipe,
      DaysUntilTodayPipe,
      TimestampToHumanReadableTimePipe,
      SafeUrlPipe,
      ReversePipe
    ]
})
export class AppPipesModule { }
