/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage | null = null;

  constructor(private storage: Storage) {}

  async init() {
    console.log('Init StorageService');
    try {
      const storage = await this.storage.create();
      this._storage = storage;
    } catch (e) {
      console.error(e);
    }
  }

  public async set(key: string, value: any) {
    if (!this._storage) {
      await this.init();
    }
    return this._storage.set(key, value);
  }

  public async get(key: string) {
    if (!this._storage) {
      await this.init();
    }
    return this._storage.get(key);
  }

  public async remove(key: string) {
    if (!this._storage) {
      await this.init();
    }
    return this._storage.remove(key);
  }

  public async clear() {
    if (!this._storage) {
      await this.init();
    }
    return this._storage.clear();
  }

}
