<ion-card *ngIf="showInfoBox | push">
  <ion-item lines="full">
    <ion-label>
      <strong>Fehlerbehebung</strong>
    </ion-label>
    <ion-button color="dark" size="small" fill="clear" slot="end" (click)="close()">
      <ion-icon name="close" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-item>
  <ion-card-content>
    <p>Einige Nutzer:innen meldeten das Problem, dass sie nach dem Beenden der App ausgeloggt wurden. Sollte das Problem
      weiterhin existieren, kontaktiere bitte den Support.</p>
  </ion-card-content>
  <ion-item lines="none">
    <ion-label color="primary" (click)="contactSupport()">
      Support kontaktieren
    </ion-label>
  </ion-item>
</ion-card>