<!-- <ion-icon name="logo-google-playstore" color="light" class="header-icon"></ion-icon> -->

<div class="page-container">
  <div class="text-container">

    <!-- Title -->
    <h1 class="title" [innerHTML]="texts[type].headline"></h1>

    <!-- Text -->
    <p class="text" [innerHTML]="texts[type].body"></p>
  </div>

  <!-- Buttons -->
  <ion-row>
    <ion-col>
      <ion-button expand="block" size="small" strong (click)="performUpdate()">
        {{ texts[type].cta }}
      </ion-button>
    </ion-col>
    <ion-col>
      <ion-button fill="clear" expand="block" size="small" class="secondary" (click)="installLater()">
        Später
      </ion-button>
    </ion-col>
  </ion-row>

</div>