import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: number | string | Date): string {

    return formatDistance(
      new Date(value),
      new Date(),
      {
        addSuffix: true,
        locale: de
      }
    );
  }

}
