/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CacheService } from 'ionic-cache';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

// Cache TTL
const ONE_HOUR = 60 * 60;
const SIX_HOURS = ONE_HOUR * 6;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = ONE_DAY * 7;
const THIRTY_MIN = ONE_HOUR / 2;

@Injectable({
  providedIn: 'root'
})
export class SanityService {

  private httpClient = inject(HttpClient);
  private cacheService = inject(CacheService);
  private platform = inject(Platform);

  sendRequest(query: string, groupKey: string, ttl: number, forceRefresh?: boolean): Observable<any> {
    query = encodeURIComponent(query);

    const url = `https://${environment.sanity.projectId}.api.sanity.io/v2021-06-07/data/query/${environment.sanity.dataset}?query=${query}`;

    const request = this.httpClient.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${environment.sanity.token}`
      }
    }).pipe(
      catchError(error => {
        // Return null observable on error
        console.log('Error in Sanity HTTP Request:', error);
        return of(null);
      })
    );

    if (forceRefresh) {
      // Convert the clearGroup promise to an observable and wait for it to complete before continuing
      const clearGroupObservable = from(this.cacheService.clearGroup(groupKey));
      return clearGroupObservable.pipe(
        switchMap(() => this.cacheService.loadFromObservable(query, request, null, ttl))
      );
    } else {
      // If forceRefresh is not true, we do not need to clear the group, so we return the original observable
      return this.cacheService.loadFromObservable(query, request, null, ttl);
    }
  }

  getNavigationItems(forceRefresh?: boolean): Observable<NavigationItem[]> {
    const query = `*[_type == "doc.mobileAppSettings" && _id == "mobileAppSettings"][0]{
      "updated": _updatedAt,
      "appCategoryNavigation": appCategoryNavigation->itemsSection[]{
          title,
          url
      }
    }`;

    return this.sendRequest(query, 'menu', ONE_WEEK, forceRefresh)
      .pipe(map(resp => resp.result.appCategoryNavigation));
  }

  getMorePageItems(forceRefresh?: boolean): Observable<MorePageItem[]> {
    const query = `*[_type == "doc.mobileAppSettings" && _id == "mobileAppSettings"][0]{
      "updated": _updatedAt,
      "appMenuMore": appMenuMore[]{
        _key,
        _type,
        title,
        description,
        "icon": icon{
          asset->{url}
        },
        target,
        inAppBrowser,
        "url": coalesce(url, '/app')
      }
    }`;

    return this.sendRequest(query, 'more', ONE_HOUR, forceRefresh)
      .pipe(map(resp => resp.result.appMenuMore));
  }

  getAffiltateLinks(forceRefresh?: boolean): Observable<{ calculator: AffiltateLinks[]; fallbackCalculator: AffiltateLinks }> {
    const query = `*[_id == "advPremiumApp"][0]{
      "calculator": calculator[]{
        startDate,
        endDate,
        "affiliateLink": provider->affiliateData.affiliateAppCalculator.affiliateLink,
        "affiliateRemark": pt::text(provider->affiliateData.affiliateAppCalculator.affiliateRemark),
        "name": provider->name,
        "providerType": provider->providerType,
        "mediaItem": provider->mediaItem{
          asset->{
            ...,
            url,
          }
        }.asset.url,
      },
      "fallbackCalculator": fallbackCalculator->{
        "affiliateLink": affiliateData.affiliateAppCalculator.affiliateLink,
        "affiliateRemark": pt::text(affiliateData.affiliateAppCalculator.affiliateRemark),
        "name": name,
        "providerType": providerType,
        "mediaItem": mediaItem{
          asset->{
            ...,
            url,
          }
        }.asset.url,
      }
    }`;

    return this.sendRequest(query, 'calculator', ONE_HOUR, forceRefresh)
      .pipe(map(resp => resp.result));
  }

  getTippBox(forceRefresh?: boolean): Observable<{ tippbox: TippBoxLinks[]; fallbackTippbox: TippBoxLinks }> {
    console.log('getTippBox ---');
    const query = `*[_id == "advPremiumApp"][0]{
      "tippbox": tippbox01[]{
        startDate,
        endDate,
        showBatch,
        "affiliateLink": provider->affiliateData.affiliateAppTippBox.affiliateLink,
        "affiliateRemark": pt::text(provider->affiliateData.affiliateAppTippBox.affiliateRemark),
        "items": provider->affiliateData.tippBox.items,
        "label": provider->affiliateData.tippBox.label,
        provider->{
          name,
          providerType,
          "mediaItem": mediaItem{
            asset->{
              ...,
              url,
            }
          }.asset.url,
          "ratingData": *[_type == "doc.review" && references(^._id)][0]{
            "count": count(*[_type in ["reviewFeedbackAward", "doc.reviewRating"] && references(^._id) && approved]),
            "rate4": count(*[_type in ["reviewFeedbackAward", "doc.reviewRating"] && references(^._id) && ratingAverage <= 5 && ratingAverage >=4 && approved]),
            "rate3": count(*[_type in ["reviewFeedbackAward", "doc.reviewRating"] && references(^._id) && ratingAverage <= 3.99 && ratingAverage >=3 && approved]),
            "rate2": count(*[_type in ["reviewFeedbackAward", "doc.reviewRating"] && references(^._id) && ratingAverage <= 2.99 && ratingAverage >=2 && approved]),
            "rate1": count(*[_type in ["reviewFeedbackAward", "doc.reviewRating"] && references(^._id) && ratingAverage <= 1.99 && ratingAverage >=1 && approved]),
          }{
          count,
          "averageTotal": round(((rate4 * 5) + (rate3 * 3) + (rate2 * 2) + (rate1 * 1)) / count, 2)
          },
        },
      },
      "fallbackTippbox": fallbackTippbox01->{
        "affiliateLink": affiliateData.affiliateAppTippBox.affiliateLink,
        "affiliateRemark": pt::text(affiliateData.affiliateAppTippBox.affiliateRemark),
        "items": affiliateData.tippBox.items,
        "label": affiliateData.tippBox.label,
        "provider": {
          "name": name,
          "providerType": providerType,
          "mediaItem": mediaItem{
            asset->{
              ...,
              url,
            }
          }.asset.url,
          "ratingData": *[_type == "doc.review" && references(^._id)][0]{
            "count": count(*[_type in ["reviewFeedbackAward", "doc.reviewRating"] && references(^._id) && approved]),
            "rate4": count(*[_type in ["reviewFeedbackAward", "doc.reviewRating"] && references(^._id) && ratingAverage <= 5 && ratingAverage >=4 && approved]),
            "rate3": count(*[_type in ["reviewFeedbackAward", "doc.reviewRating"] && references(^._id) && ratingAverage <= 3.99 && ratingAverage >=3 && approved]),
            "rate2": count(*[_type in ["reviewFeedbackAward", "doc.reviewRating"] && references(^._id) && ratingAverage <= 2.99 && ratingAverage >=2 && approved]),
            "rate1": count(*[_type in ["reviewFeedbackAward", "doc.reviewRating"] && references(^._id) && ratingAverage <= 1.99 && ratingAverage >=1 && approved]),
          }{
          count,
          "averageTotal": round(((rate4 * 5) + (rate3 * 3) + (rate2 * 2) + (rate1 * 1)) / count, 2)
          },
        }
      }
    }`;

    return this.sendRequest(query, 'tipp-box', 0, true)
      .pipe(map(resp => resp.result));
  }
}

interface NavigationItem {
  title: string;
  url: string;
}

export interface MorePageItem {
  title: string;
  icon: {
    asset: {
      url: string;
    };
  };
  inAppBrowser: boolean;
  description: string;
  open: 'extern' | 'app';
  url: string;
}

export interface AffiltateLinks {
  affiliateLink: string;
  affiliateRemark: string;
  name: string;
  providerType: string;
  mediaItem: string;
  startDate: string;
  endDate: string;
}

export interface TippBoxLinks {
  affiliateLink: string;
  affiliateRemark: string;
  label: string;
  items: string[];
  provider: Provider;
  startDate: string;
  endDate: string;
}

export interface Provider {
  mediaItem: string;
  name: string;
  providerType: string;
  ratingData: {
    averageTotal: number;
    count: number;
  };
}
