import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-successful-purchase-modal',
  templateUrl: './successful-purchase-modal.component.html',
  styleUrls: ['./successful-purchase-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessfulPurchaseModalComponent implements OnInit {
  @Input() isUserLoggedIn = false;

  public adventages: string[] = [
    'Greife auf dein Abo auf <b>allen Endgeräten</b> zu',
    'Verwalte Deine <b>Abonnements</b>, Adress- und Zahlungsdaten',
    'Abonniere <b>Newsletter</b>, Push-Benachrichtigungen, Mailings und mehr',
    'Erhalte <b>exklusive Angebote</b> von unseren Branchen-Partnern'
  ];

  private userService = inject(UserService);
  private modalController = inject(ModalController);

  ngOnInit() { }

  async closeModal() {
    this.modalController.dismiss();
  }

  async openLogin() {

    this.userService.login();

    await this.closeModal();

  }

}
