import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const termsToHide = [
  'nonce',
  'PKCE_verifier',
  'session_state',
  'access_token',
  'refresh_token',
  'id_token',
  'id_token_claims_obj'
];

@Injectable({
  providedIn: 'root'
})
export class TokenExchangeService {

  private items = {};

  set(key: string, value: string): void {

    // Convert value to string or save empty string
    this.items[key] = value ? String(value) : '';

    if (environment.production && termsToHide.includes(key) && value) {
      try {
        console.log('Token Exchange - SET', key, Array(String(this.items[key]).length + 1).join('*'));
      } catch (e) { }
    } else {
      console.log('Token Exchange - SET', key, this.items[key]);
    }
  }

  get(key: string): string | undefined {

    // Convert value to string or return empty string
    const value = this.items[key] ? String(this.items[key]) : '';

    if ((environment.production && termsToHide.includes(key) && value)) {
      try {
        console.log('Token Exchange - GET', key, Array(value.length + 1).join('*'));
      } catch (e) { }
    } else {
      console.log('Token Exchange - GET', key, value);
    }

    return value;
  }

  remove(key: string): void {
    delete this.items[key];
    console.log('Token Exchange - REMOVE', key);
  }

  getAll(): Record<string, string> {
    console.log('Token Exchange - GETALL', this.items);
    return this.items;
  }

}
