/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { NgModule } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ApolloModule, APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloClientOptions, ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from 'src/environments/environment';
import { UtilitiesService } from './services/utilities.service';
import { Preferences } from '@capacitor/preferences';

const API_URL = environment.btcecho.apiUrl;
const API_URL_SHOP = environment.btcecho.apiUrlShop;
const API_AUTH = environment.btcecho.apiAuth;

export const createApolloMain = (httpLink: HttpLink): ApolloClientOptions<any> => ({
  link: httpLink.create({
    uri: API_URL,
    headers: new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Authorization', `Basic ${API_AUTH}`)
  }),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  }
});

/*

Authenticating with JWT

*/
const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  // eslint-disable-next-line max-len
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  return JSON.parse(jsonPayload);
};

const isTokenValid = (token: string) => {
  const jwt = parseJwt(token);
  const exp = jwt.exp;
  const now = new Date().getTime() / 1000;
  return exp > now;
};

const getToken = async (utilitiesService: UtilitiesService) => {
  const token = await Preferences.get({ key: 'authToken' });
  // console.log('[Token] Step 1 - New request with token: ', token);
  if (token?.value) {
    const isValid = isTokenValid(token.value);
    if (isValid) {
      // console.log('[Token] Step 2 - Token is valid');
      return token.value;
    } else {
      // console.log('[Token] Step 2 - Token is not valid');
      const refreshToken = await Preferences.get({ key: 'refreshToken' });
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Basic ${API_AUTH}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `mutation RefreshTokenMutation {
              refreshJwtAuthToken(
                input: {jwtRefreshToken: "${refreshToken.value}"}
              ) {
                authToken
                clientMutationId
              }
            }`})
      };

      try {
        console.log('Refresh Auth Token...');
        const response = await utilitiesService.httpRequest(API_URL_SHOP, options);
        const newToken = response.data?.refreshJwtAuthToken?.authToken;
        // console.log('[Token] Step 3 - New token: ', newToken);
        await Preferences.set({ key: 'authToken', value: newToken });
        return newToken;
      } catch (error) {
        console.error('Could not renew auth token');
      }

    }
  } else {
    return '';
  }
};

// Middleware, which will be called before the network request is made by Apollo
// https://github.com/apollographql/apollo-client/issues/2441#issuecomment-1092389150
const authLink = (utilitiesService: UtilitiesService) => setContext(async (operation) => {
  const token = await getToken(utilitiesService);
  const wooSessionToken = await Preferences.get({ key: 'wooSessionToken'});
  // console.log('[Token] Step 4 - Send request with token: ', token);

  const headers = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  if (wooSessionToken?.value) {
    headers.headers['woocommerce-session'] = `Session ${wooSessionToken.value}`;
  }

  return headers;
});

export const createApolloShopUserAuth = (httpLink: HttpLink, utilitiesService: UtilitiesService) => ({
  shop: {
    link: httpLink.create({
      uri: API_URL_SHOP,
      headers: new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Authorization', `Basic ${API_AUTH}`)
    }),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      }
    }
  },
  shopUserAuth: {
    link: ApolloLink.from([
      authLink(utilitiesService),
      httpLink.create({
        uri: API_URL_SHOP,
      })]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      }
    }
  }
});

@NgModule({
  imports: [
    ApolloModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApolloMain,
      deps: [HttpLink],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApolloShopUserAuth,
      deps: [HttpLink, UtilitiesService],
    }
  ],
})

export class GraphQLModule { }
