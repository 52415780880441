/* Frameworks & Libraries */
import { Component, OnInit, ChangeDetectionStrategy, inject } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
/* Services */
import { SettingsService, Theme } from '../../services/settings.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { FileService } from 'src/app/services/file.service';
import { LoggerService } from 'src/app/services/logger.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService, UserInfo } from 'src/app/services/user.service';
import { CacheService } from 'ionic-cache';
/* Environment */
import { environment } from 'src/environments/environment';
/* Capacitor */
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Share } from '@capacitor/share';
import { Dialog } from '@capacitor/dialog';
import { RateApp } from 'capacitor-rate-app';
import { TextZoom } from '@capacitor/text-zoom';
import { Preferences } from '@capacitor/preferences';
import { CapacitorCookies } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import { EmailComposer } from 'capacitor-email-composer';

const APP_STORE_LINK = 'https://apps.apple.com/app/id1600917142';
const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=de.btcecho.app';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsPage implements OnInit {

  public isBetaTester$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public user$: BehaviorSubject<UserInfoExtended> = new BehaviorSubject(null);
  public avatarUrl$: BehaviorSubject<string> = new BehaviorSubject(null);
  public textZoomRangeValue$: BehaviorSubject<number> = new BehaviorSubject(3);

  public isSubscribedToPushNotifications$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasActiveSubscription$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isSubscribedToPush = false;
  appVersion = new BehaviorSubject('0.0.0 (0)');
  envInfo: string = null;
  theme: Theme;
  socialLinks = [
    {
      icon: 'logo-facebook',
      link: 'https://www.facebook.com/bitcoinecho'
    },
    {
      icon: 'logo-twitter',
      link: 'https://twitter.com/btcecho'
    },
    {
      icon: 'logo-instagram',
      link: 'https://www.instagram.com/btcecho/'
    }
  ];
  private betaTesterClickCount = 0;
  private userService = inject(UserService);

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private settingsService: SettingsService,
    private router: Router,
    private cacheService: CacheService,
    private utilitiesService: UtilitiesService,
    private fileService: FileService,
    private loggerService: LoggerService,
    private storageService: StorageService) { }

  async ngOnInit() {

    this.getUser();
    this.getTextZoom();

    this.userService.hasActiveSubscription$.subscribe(value => {
      this.hasActiveSubscription$.next(Boolean(value));
      console.log('Active Subscription:', value);
    });

    if (this.platform.is('capacitor')) {
      // Display App Version
      const { version, build } = await App.getInfo();
      this.appVersion.next(`${version} (${build})`);
      // Set push notification toggle
      this.isSubscribedToPushNotifications$.next(OneSignal.User.pushSubscription.optedIn);
    }

    this.theme = await this.settingsService.getTheme();
    this.envInfo = environment.name;

    this.settingsService.userIsBetaTester$.subscribe((isBetaTester) => {
      this.isBetaTester$.next(isBetaTester);
    });
  }

  async navigateToPage(url: string) {
    await this.router.navigate([`/${url}`]);
    await this.close();
  }

  async login() {
    this.userService.login();
    this.close();
    // if (loginResponse) {
    //   await this.getUser();
    // }
  }

  async logout() {
    const { value } = await Dialog.confirm({
      title: 'Abmelden?',
      message: 'Möchtest du dich wirklich abmelden?'
    });
    if (value) {
      await this.userService.logout();
      this.user$.next(null);
    }
  }

  async showSubscriptionInfo() {
    await Dialog.alert({
      title: 'Abonnement',
      message: 'Typ: ' + this.userService.hasActiveSubscription$.value.toUpperCase()
    });
  }

  async toggleDarkMode(event: any) {
    const theme: Theme = event.detail.value;
    await this.settingsService.setTheme(theme);
  }

  async toggleNotifications(event: any) {
    if (this.platform.is('capacitor')) {
      // If the user is subscribed, the ion-toggle will be set to true in ngOnInit.
      // This will trigger this function (toggleNotifications()).
      // So the user will be subscribed every time he opens the settings page.
      // To prevent this, wie need to check if the user is already subscribed:

      let hasPermission = OneSignal.Notifications.hasPermission();
      if (event.detail.checked && !hasPermission) {
        await Dialog.alert({
          title: 'Fehlende Berechtigung',
          message: 'Es scheint so, als hätte diese App nicht die nötigen Berechtigungen. '
            + 'Bitte erlaube das Senden von Mitteilungen in den Einstellungen Deines Gerätes.'
        });
        // Opens settings as fallback
        hasPermission = await OneSignal.Notifications.requestPermission(true);
        if (!hasPermission) {
          this.isSubscribedToPushNotifications$.next(false);
          return;
        }
      }

      if (event.detail.checked && !OneSignal.User.pushSubscription.optedIn) {
        OneSignal.User.pushSubscription.optIn();
        this.isSubscribedToPushNotifications$.next(false);
      } else if (!event.detail.checked && OneSignal.User.pushSubscription.optedIn) {
        OneSignal.User.pushSubscription.optOut();
        this.isSubscribedToPushNotifications$.next(false);
      }
    }
  }

  async onTextSizeChange(event: any) {
    const value: number = event.detail.value;
    switch (value) {
      case 1:
        await this.setTextZoom(0.8);
        break;
      case 2:
        await this.setTextZoom(0.9);
        break;
      case 3:
        await this.setTextZoom(1);
        break;
      case 4:
        await this.setTextZoom(1.1);
        break;
      case 5:
        await this.setTextZoom(1.2);
        break;
      case 6:
        await this.setTextZoom(1.3);
        break;
      default:
        await this.setTextZoom(1);
        break;
    }
  }

  async setTextZoom(value: number) {

    if (!this.platform.is('capacitor')) {
      return;
    }

    await TextZoom.set({ value });
    await this.storageService.set('textZoom', value);
  }

  async rateApp() {
    if (this.platform.is('capacitor')) {
      const alert1 = await Dialog.confirm({
        title: 'Gefällt Dir die App?',
        message: `Bist du mit der BTC-ECHO App zufrieden?`,
        okButtonTitle: 'Ja',
        cancelButtonTitle: 'Nein'
      });

      if (alert1.value) {
        try {
          await RateApp.requestReview();
          setTimeout(async () => {
            await this.utilitiesService.showToast('Vielen Dank für Deine Bewertung! Es freut uns sehr, dass Dir die App gefällt.');
          }, 1000);
        } catch {
          window.location.href = this.platform.is('ios') ? APP_STORE_LINK : GOOGLE_PLAY_LINK;
        }
      } else {
        const alert2 = await Dialog.confirm({
          title: 'Was können wir verbessern?',
          message: `Es tut uns leid, dass Dir die App nicht gefällt. Möchtest du uns mitteilen, was wir verbessern können?`,
          okButtonTitle: 'Nachricht senden',
          cancelButtonTitle: 'Schließen'
        });
        if (alert2.value) {
          this.writeMail();
        }
      }
    }
  }

  async shareApp() {
    await this.settingsService.setAppReviewAnswered();

    if (this.platform.is('capacitor')) {
      const shareConfig = {
        title: 'BTC-ECHO App',
        text: `BTC-ECHO App kostenlos laden!\niOS: ${APP_STORE_LINK}\nAndroid: ${GOOGLE_PLAY_LINK}`,
        dialogTitle: 'Teilen',
      };
      try {
        const { activityType } = await Share.share(shareConfig);
        // Log Share Event
        await this.loggerService.logShareEvent({
          method: activityType,
          item_id: 'share-app'
        });
      } catch (error) {
        console.error('Share Sheet Error:', error);
      }
    }
  }

  async writeMail() {
    try {
      await EmailComposer.open({
        to: ['support@btc-echo.de']
      });
    } catch (error) {
      await Dialog.alert({
        title: 'Support',
        message: 'Bitte sende eine E-Mail an support@btc-echo.de'
      });
    }
  }

  openBrowser(url: string) {
    Browser.open({
      url,
      presentationStyle: 'popover'
    });
  }

  async resetApp() {
    const alert = await Dialog.confirm({
      title: 'App zurücksetzen?',
      message: `Der Cache der App wird gelöscht und du wirst ausgeloggt.`,
      okButtonTitle: 'Weiter',
      cancelButtonTitle: 'Abbrechen'
    });
    if (alert.value) {
      await Preferences.clear();
      await CapacitorCookies.clearAllCookies();
      await Promise.allSettled([
        this.userService.logout(),
        this.cacheService.clearAll(),
        this.fileService.clearAllFiles()
      ]);
      await this.utilitiesService.showToast('App zurückgesetzt!');
      await this.close();
    }
  }

  async close() {
    await this.modalCtrl.dismiss();
  }

  async setUserAsBetaTester() {
    this.betaTesterClickCount++;

    if (this.betaTesterClickCount === 3) {
      const userIsBetaTester = this.settingsService.userIsBetaTester$.value;
      await this.settingsService.setUserAsBetaTester(!userIsBetaTester);
      this.utilitiesService.showToast(!userIsBetaTester ? 'Du bist nun Beta Tester!' : 'Du bist nun kein Beta Tester mehr!');
      this.betaTesterClickCount = 0;
    }
  }

  openProfilePage() {
    Browser.open({
      url: environment.unidy.issuer + '/profile/',
      presentationStyle: 'popover'
    });
  }


  /**
   * Get current user and display it's information
   */
  private async getUser() {
    this.userService.getUser().subscribe(user => this.user$.next(user));
  }

  /**
   * Get Text Zoom Settings and display them in UI
   */
  private async getTextZoom() {

    if (!this.platform.is('capacitor')) {
      return;
    }

    try {
      const { value } = await TextZoom.get();
      const rangeValue = await this.getRangeValueFromZoomText(value);
      this.textZoomRangeValue$.next(rangeValue);
    } catch (error) {
      console.error(error);
    }
  }

  private async getRangeValueFromZoomText(value: number) {
    switch (value) {
      case 0.8:
        return 1;
      case 0.9:
        return 2;
      case 1:
        return 3;
      case 1.1:
        return 4;
      case 1.2:
        return 5;
      case 1.3:
        return 6;
      default:
        return 3;
    }
  }
}

interface UserInfoExtended extends UserInfo {
  avatar: {
    url: string;
  };
};
