import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
// Capacitor
import { AppUpdate } from '@capawesome/capacitor-app-update';

/**
 * This component shows as bottom sheet and is used to inform users about
 * new in-app-updates or store updates. The component handles the in-app-update
 * installation and the redirect to the app stores fot native updates.
 */

@Component({
  selector: 'app-update-notifier',
  templateUrl: './update-notifier.component.html',
  styleUrls: ['./update-notifier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateNotifierComponent implements OnInit {

  @Input() type: 'store' | 'in-app';
  @Input() storeVersion?: string; // Only on 'store'

  public platform = inject(Platform);
  public isIOS = this.platform.is('ios');

  public texts = {
    'in-app': {
      headline: 'Ein Update steht zur Installation bereit',
      body: 'Ein In-App-Update Update wurde geladen und steht zur Installation bereit. Jetzt installieren?',
      cta: 'Jetzt installieren',
    },
    store: {
      headline: `Neue Version ${this.isIOS ? 'im<br>App Store' : 'in<br>Google Play'} verfügbar`,
      body: `Eine neue Version steht ${this.isIOS ? 'im App Store' : 'in Google Play'} zum Download bereit. Möchtest du sie jetzt laden?`,
      cta: this.isIOS ? 'Zum App Store' : 'Zu Google Play',
    }
  };

  private modalController = inject(ModalController);

  async ngOnInit() { }

  /**
   * Installs the recently downloaded in-app-update
   */
  async performUpdate() {

    // Close modal
    await this.modalController.dismiss();

    // Stop if not capacitor
    if (!this.platform.is('capacitor')) {
      return;
    }

    // Perform in-app-update or redirect to app store
    try {
      switch (this.type) {
        case 'store':
          await AppUpdate.openAppStore();
          break;
      }
    } catch (error) {
      alert('Fehler: ' + error);
    }
  }

  /**
   * Install the in-app-update update on the next launch of the app
   */
  async installLater() {

    try {
      // Close modal
      await this.modalController.dismiss();
    } catch (error) {
      alert('Fehler: ' + error);
    }
  }

}
