<ion-header>
  <ion-toolbar>
    <ion-title>Einstellungen</ion-title>
    <ion-buttons slot="primary">
      <ion-button fill="clear" (click)="close()">
        Fertig
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content *rxLet="user$ as user">

  <app-info-box></app-info-box>

  <div class="user-account">
    <ion-icon name="person-circle-outline" color="light" *ngIf="!user?.avatar.url"></ion-icon>
    <img [src]="user?.avatar.url" class="avatar" *ngIf="user?.avatar.url" draggable="false">

    <ion-label color="medium">
      {{ user ? 'Hallo,' : 'Du bist nicht angemeldet.' }}
    </ion-label>
    <h4 class="ion-no-margin" *ngIf="user">
      {{user?.given_name || user?.email || user?.name}}
    </h4>
    <ion-chip color="primary" [outline]="true" (click)="showSubscriptionInfo()"
      *ngIf="hasActiveSubscription$ | push">Plus+ Abonnent</ion-chip>
  </div>

  <ion-list>
    <app-item-divider [text]="'Profil'"></app-item-divider>
    <ion-item detail lines="none" *ngIf="!user" (click)="login()">
      <ion-icon slot="start" color="primary" name="person-circle-outline"></ion-icon>
      <ion-label>Anmelden</ion-label>
    </ion-item>
    <ion-item detail lines="none" (click)="navigateToPage('bookmarks')">
      <ion-icon slot="start" color="primary" name="bookmarks-outline"></ion-icon>
      <ion-label>Bookmarks</ion-label>
    </ion-item>
    <ion-item detail lines="none" *ngIf="user" (click)="openProfilePage()" [disabled]="!user">
      <ion-icon slot="start" color="primary" name="person-circle-outline"></ion-icon>
      <ion-label>Daten ändern</ion-label>
    </ion-item>
    <ion-item detail lines="full" *ngIf="user" (click)="logout()" [disabled]="!user">
      <ion-icon slot="start" color="primary" name="log-out-outline"></ion-icon>
      <ion-label>Abmelden</ion-label>
    </ion-item>
  </ion-list>

  <ion-list>
    <app-item-divider [text]="'Generell'"></app-item-divider>
    <ion-item lines="none">
      <ion-icon slot="start" color="primary" name="notifications-outline"></ion-icon>
      <ion-label>Benachrichtigungen</ion-label>
      <ion-toggle (ionChange)="toggleNotifications($event)"
        [checked]="isSubscribedToPushNotifications$ | push"></ion-toggle>
    </ion-item>
    <ion-item lines="none">
      <ion-icon slot="start" color="primary" name="color-filter-outline"></ion-icon>
      <ion-label>Design</ion-label>
      <ion-segment slot="end" value="auto" [value]="theme" (ionChange)="toggleDarkMode($event)">
        <ion-segment-button value="auto">
          <ion-label>Auto</ion-label>
        </ion-segment-button>
        <ion-segment-button value="light">
          <ion-icon name="sunny-outline"></ion-icon>
        </ion-segment-button>
        <ion-segment-button value="dark">
          <ion-icon name="moon-outline"></ion-icon>
        </ion-segment-button>
      </ion-segment>
    </ion-item>
    <ion-item lines="none">
      <ion-icon slot="start" color="primary" name="text-outline"></ion-icon>
      <ion-label>Textgröße</ion-label>
    </ion-item>
    <ion-item lines="none" class="range">
      <ion-label>Klein</ion-label>
      <ion-range aria-label="Volume" min="1" max="6" [value]="textZoomRangeValue$ | push" snaps="true"
        (ionChange)="onTextSizeChange($event)"></ion-range>
      <ion-label>Groß</ion-label>
    </ion-item>
    <ion-item detail (click)="rateApp()" lines="none">
      <ion-icon slot="start" color="primary" name="star-outline"></ion-icon>
      <ion-label>
        App bewerten
      </ion-label>
    </ion-item>
    <ion-item detail (click)="shareApp()" lines="none">
      <ion-icon slot="start" color="primary" ios="share-outline" md="share-social"></ion-icon>
      <ion-label>
        App empfehlen
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-list>
    <app-item-divider [text]="'Hilfe & Kontakt'"></app-item-divider>
    <ion-item detail (click)="writeMail()" lines="none">
      <ion-icon slot="start" color="primary" name="mail-outline"></ion-icon>
      <ion-label>
        E-Mail senden
      </ion-label>
    </ion-item>
    <ion-item class="social-media" lines="none">
      <ion-icon slot="start" color="primary" name="share-social"></ion-icon>
      <ion-label>
        Social Media
      </ion-label>
      <!-- TODO: Open specific app instead of browser -->
      <ion-button fill="clear" slot="end" *ngFor="let platform of socialLinks" [href]="platform.link">
        <ion-icon *ngIf="platform?.icon" [name]="platform.icon" slot="icon-only" color="primary"></ion-icon>
        <!-- <ion-icon *ngIf="platform?.iconSrc" [src]="platform.iconSrc" slot="icon-only" color="primary"></ion-icon> -->
      </ion-button>
    </ion-item>
  </ion-list>

  <ion-list>
    <app-item-divider [text]="'Rechtliches'"></app-item-divider>
    <ion-item detail (click)="openBrowser('https://www.btc-echo.de/impressum/')" lines="none">
      <ion-icon slot="start" color="primary" name="link-outline"></ion-icon>
      <ion-label>
        Impressum
      </ion-label>
    </ion-item>
    <ion-item detail (click)="openBrowser('https://www.btc-echo.de/datenschutzerklaerung-app/')" lines="none">
      <ion-icon slot="start" color="primary" name="link-outline"></ion-icon>
      <ion-label>
        Datenschutz
      </ion-label>
    </ion-item>
    <ion-item detail (click)="openBrowser('https://www.btc-echo.de/agb/')" lines="none">
      <ion-icon slot="start" color="primary" name="link-outline"></ion-icon>
      <ion-label>
        Nutzungshinweise
      </ion-label>
    </ion-item>
  </ion-list>

  <div class="dev-info">
    <p>App entwickelt von <img (click)="openBrowser('https://eyloo.com/?utm_source=btce-app')" draggable="false"
        class="developer-logo" src="/assets/logo/eyloo-logo-colored.png"></p>
    <p class="version" (click)="setUserAsBetaTester()">
      Version: {{ appVersion | push }} - {{ envInfo | uppercase }}
      <br>
      Release Channel: {{ (isBetaTester$ | push) ? 'BETA' : 'STABLE' }}
    </p>
  </div>

  <ion-button fill="clear" expand="block" color="danger" size="small" class="reset-button" (click)="resetApp()">
    App zurücksetzen
  </ion-button>

</ion-content>