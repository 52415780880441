import { Browser } from '@capacitor/browser';
import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.unidy.issuer,
  redirectUri: environment.unidy.callbackUrl,
  clientId: environment.unidy.clientId,
  postLogoutRedirectUri: null,
  responseType: 'code',
  scope: 'openid profile email address subscriptions:read subscriptions:write active_subscriptions',
  showDebugInformation: true,
  requestAccessToken: true,
  checkOrigin: false,
  skipIssuerCheck: true,
  useSilentRefresh: false,
  requireHttps: false,
  openUri: (url: string) => {
    // Only open login link in browser, not logout callback
    if (url && url.includes('oauth/authorize')) {
      Browser.open({
        url,
        windowName: '_self',
        presentationStyle: 'popover'
      });
    }
  }
};
